export default {
	header: {
		self: {},
		items: [
			{
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Categorias",
				root: true,
				icon: "fas fa-tag",
				page: "categorias",
				bullet: "dot"
			},
			{
				title: "Artistas",
				root: true,
				bullet: "dot",
				icon: "fas fa-building",
				submenu: [
					{
						title: "Aguardando/Reprovados",
						bullet: "dot",
						page: "artistas"
					},
					{
						title: "Aprovados",
						bullet: "dot",
						page: "artistas-aprovados"
					},
				]
			},
			{
				title: "Contratantes",
				root: true,
				icon: "fas fa-id-card",
				page: "contratantes",
				bullet: "dot"
			},
			{
				title: "Avaliações",
				root: true,
				icon: "fas fa-star",
				page: "avaliacao",
				bullet: "dot"
			},
			{
				title: "Contratações",
				root: true,
				icon: "fas fa-file-alt",
				page: "contratacoes",
				bullet: "dot"
			},
			{
				title: "Pagamentos",
				root: true,
				icon: "fas fa-money-bill-alt",
				page: "pagamento",
				bullet: "dot"
			},
			{
				title: "Relatórios",
				root: true,
				bullet: "dot",
				icon: "fas fa-chart-pie",
				submenu: [
					{
						title: "Contratações",
						bullet: "dot",
						page: "relatorio/relatorio01"
					},
					{
						title: "Faturamento",
						bullet: "dot",
						page: "relatorio/relatorio02"
					},
				]
			},
			{
				title: "Administradores",
				root: true,
				icon: "fas fa-user-shield",
				page: "admin",
				bullet: "dot"
			},
			{
				title: "Grupos de Acesso",
				root: true,
				icon: "fas fa-layer-group",
				page: "group",
				bullet: "dot"
			},
		]
	}
};
