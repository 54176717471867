import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const GroupPage = lazy(() =>
	import("./group/GroupPage")
);

const AdminPage = lazy(() =>
	import("./admin/AdminPage")
);

const UserPage = lazy(() =>
	import("./user/UserPage")
);

const CategoriaPage = lazy(() =>
	import("./categoria/CategoriaPage")
);

const ArtistaPage = lazy(() =>
	import("./artista/ArtistaPage")
);

const ArtistaAprovadosPage = lazy(() =>
	import("./artista/ArtistaAprovadosPage")
);

const ContratantePage = lazy(() =>
	import("./contratante/ContratantePage")
);

const AvaliacaoPage = lazy(() =>
	import("./avaliacao/AvaliacaoPage")
);

const ContratacoesPage = lazy(() =>
	import("./contratacoes/ContratacoesPage")
);

const RelatorioPage = lazy(() =>
	import("./relatorio/RelatorioPage")
);

const PagamentoPage = lazy(() =>
	import("./pagamento/PagamentoPage")
);

export default function HomePage() {

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					<Redirect exact from="/" to="/dashboard" />
				}

				<Route path="/dashboard" component={DashboardPage} />
				<Route path="/group" component={GroupPage} />
				<Route path="/admin" component={AdminPage} />
				<Route path="/user" component={UserPage} />
				<Route path="/categorias" component={CategoriaPage} />
				<Route path="/artistas" component={ArtistaPage} />
				<Route path="/artistas-aprovados" component={ArtistaAprovadosPage} />
				<Route path="/contratantes" component={ContratantePage} />
				<Route path="/avaliacao" component={AvaliacaoPage} />
				<Route path="/contratacoes" component={ContratacoesPage} />
				<Route path="/relatorio" component={RelatorioPage} />
				<Route path="/pagamento" component={PagamentoPage} />

				<Redirect to="/error/error-v6" />
			</Switch>
		</Suspense>
	);
}
